import * as React from "react";

import { Divider } from "@source-web/divider";
import { Heading } from "@source-web/heading";
import { Icon } from "@source-web/icon";
import { Span } from "@source-web/span";

import { Grid, GridColumn, GridRow } from "@vfuk/core-grid";

import PrimaryButton from "../PrimaryButton/PrimaryButton";
import {
  ServiceDataType,
  ServiceTileProps,
  WrapperTypes
} from "./ServiceTile.types";
import * as Styled from "./styles/ServiceTile.style";

const ServiceTile = ({
  headingText,
  buttonProps,
  iconAltText,
  icon,
  recentReports,
  serviceDetail,
  pillComponent,
  isSelfServe,
  myServiceCard,
  ...otherProps
}: ServiceTileProps) => {
  const [isExpanded, toggleExpanded] = React.useState(false);
  const {
    statusLabel,
    viewMoreButton,
    viewLessButton,
    viewButton,
    recentReportsLabel,
    serviceDetailHeading
  } = myServiceCard;

  const tileIcon = (
    <Styled.IconWrapper>
      <img src={icon} alt={iconAltText} />
    </Styled.IconWrapper>
  );

  const TileDivider = () => (
    <Styled.DividerWrapper>
      <Divider appearance="secondary" />
    </Styled.DividerWrapper>
  );

  const TransitionalDivider = () => (
    <Styled.DividerWrapperTransitional isExpanded={isExpanded}>
      <Divider noMargin appearance="secondary" />
    </Styled.DividerWrapperTransitional>
  );

  const StatusContainer = () => (
    <Styled.StatusWrapper>
      <Span>{statusLabel}:</Span>
      {pillComponent}
    </Styled.StatusWrapper>
  );

  const ViewMoreLessToggle = ({
    isSelfServe
  }: {
    isSelfServe: boolean | undefined;
  }) =>
    !isSelfServe && (
      <Styled.ViewMoreLessWrapper>
        <Styled.InteractiveIconWrapper
          onClick={() => toggleExpanded(!isExpanded)}
        >
          <span>{isExpanded ? viewLessButton : viewMoreButton}</span>
          <Icon
            name={isExpanded ? "chevron-up" : "chevron-down"}
            size={1}
            appearance="secondary"
          />
        </Styled.InteractiveIconWrapper>
      </Styled.ViewMoreLessWrapper>
    );

  const SectionSubTile = ({
    report,
    isMobile
  }: WrapperTypes & { report: ServiceDataType }) => (
    <Styled.SectionSubTileWrapper>
      {report.name}
      <Styled.SectionSubTileUrl>
        <Styled.ReportsButton onClick={report.onClick}>
          {isMobile ? (
            <Styled.InteractiveIconWrapper>
              <Icon name="chevron-right" size={1} appearance="secondary" />
            </Styled.InteractiveIconWrapper>
          ) : (
            viewButton
          )}
        </Styled.ReportsButton>
      </Styled.SectionSubTileUrl>
    </Styled.SectionSubTileWrapper>
  );

  const RecentReportsContainer = ({ isMobile }: WrapperTypes) => (
    <Styled.SubTileContainer>
      <Heading size={1} weight={3}>
        {recentReportsLabel}
      </Heading>
      {recentReports &&
        recentReports.map((report: ServiceDataType, reportId) => (
          <SectionSubTile report={report} isMobile={isMobile} key={reportId} />
        ))}
    </Styled.SubTileContainer>
  );

  const ServiceDetailContainer = ({ isMobile }: WrapperTypes) => (
    <Styled.SubTileContainer>
      <Heading size={1} weight={3}>
        {serviceDetailHeading}
      </Heading>
      {serviceDetail &&
        serviceDetail.map((detail: ServiceDataType, detailId) => (
          <SectionSubTile report={detail} isMobile={isMobile} key={detailId} />
        ))}
    </Styled.SubTileContainer>
  );

  const HiddenSectionContainer = ({ isMobile }: WrapperTypes) => (
    <Styled.HiddenSectionWrapper isExpanded={isExpanded}>
      <Styled.HiddenSection>
        <RecentReportsContainer isMobile={isMobile} />
      </Styled.HiddenSection>
      <TileDivider />
      <Styled.HiddenSection>
        {serviceDetail && serviceDetail?.length > 0 ? (
          <ServiceDetailContainer isMobile={isMobile} />
        ) : null}
      </Styled.HiddenSection>
    </Styled.HiddenSectionWrapper>
  );

  return (
    <Styled.ExpandedTileWrapper {...otherProps}>
      <Styled.DesktopLayout>
        <Styled.DesktopHeaderSection>
          {tileIcon}
          <Styled.DesktopWrapper>
            <Styled.HeadingWrapper>
              <Heading text={headingText} size={2} weight={3} />
            </Styled.HeadingWrapper>
            <TileDivider />
            <Styled.SubHeadingWrapper>
              <StatusContainer />
              <Styled.GridWrapper>
                <Grid maxWidth="full">
                  <GridRow>
                    <Styled.NoPaddingColumn>
                      {ViewMoreLessToggle({ isSelfServe })}
                    </Styled.NoPaddingColumn>
                    <Styled.NoPaddingColumn alignSelf="center">
                      <Styled.GridButtonWrapper>
                        {buttonProps?.text ? (
                          <PrimaryButton {...buttonProps} />
                        ) : null}
                      </Styled.GridButtonWrapper>
                    </Styled.NoPaddingColumn>
                  </GridRow>
                </Grid>
              </Styled.GridWrapper>
            </Styled.SubHeadingWrapper>
          </Styled.DesktopWrapper>
        </Styled.DesktopHeaderSection>
        {TransitionalDivider()}
        {HiddenSectionContainer({ isMobile: false })}
      </Styled.DesktopLayout>

      <Styled.TabletLayout>
        <Styled.MobileHeaderSection>
          <Styled.HeadingWrapper>
            {tileIcon}
            <Styled.HeadingStatusWrapper>
              <Heading text={headingText} size={2} weight={3} />
              <StatusContainer />
            </Styled.HeadingStatusWrapper>
          </Styled.HeadingWrapper>
          <TileDivider />
          <Styled.SubHeadingWrapper>
            <Styled.ButtonWrapperMobile>
              {ViewMoreLessToggle({ isSelfServe })}
              <Styled.GridButtonWrapper>
                {buttonProps?.text ? <PrimaryButton {...buttonProps} /> : null}{" "}
              </Styled.GridButtonWrapper>
            </Styled.ButtonWrapperMobile>
          </Styled.SubHeadingWrapper>
        </Styled.MobileHeaderSection>
        {TransitionalDivider()}
        <Styled.HiddenSectionWrapper isExpanded={isExpanded}>
          <GridRow>
            <GridColumn>
              <Styled.HiddenSection>
                <RecentReportsContainer isMobile />
              </Styled.HiddenSection>
            </GridColumn>
            <GridColumn>
              <Styled.HiddenSection>
                {serviceDetail && serviceDetail?.length > 0 ? (
                  <ServiceDetailContainer isMobile />
                ) : null}
              </Styled.HiddenSection>
            </GridColumn>
          </GridRow>
        </Styled.HiddenSectionWrapper>
      </Styled.TabletLayout>

      <Styled.MobileLayout>
        <Styled.MobileHeaderSection>
          <Styled.HeadingWrapper>
            {tileIcon}
            <Heading text={headingText} size={2} weight={3} />
          </Styled.HeadingWrapper>
          <TileDivider />
          <Styled.SubHeadingWrapperMobile>
            <StatusContainer />
            <Styled.ButtonToggleContainer>
              <Styled.GridButtonWrapper>
                {buttonProps?.text ? <PrimaryButton {...buttonProps} /> : null}{" "}
              </Styled.GridButtonWrapper>
              {ViewMoreLessToggle({ isSelfServe })}
            </Styled.ButtonToggleContainer>
          </Styled.SubHeadingWrapperMobile>
        </Styled.MobileHeaderSection>
        {TransitionalDivider()}
        {HiddenSectionContainer({ isMobile: true })}
      </Styled.MobileLayout>
    </Styled.ExpandedTileWrapper>
  );
};

export default ServiceTile;
