import styled from "styled-components";

export const CategoryText = styled.div(
  (props) => `
    padding-bottom: ${props.theme.spacing.fixed[4]}px;

    & > h2 {
      color: ${props.theme.color.monochrome6.default};
    }

  `
);

export const ServiceListWrapper = styled.div(
  (props) => `
    min-height:100vh;
    height:100%;
    padding-bottom: ${props.theme.spacing.fixed[4]}px;
  `
);
