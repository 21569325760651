import styled, { css, keyframes } from "styled-components";

import { elevation } from "@source-web/mixins";

const skeletonAnimation = keyframes`
    0% {
        opacity:0.8
    }
    50% {
        opacity: 0.6
    }
    100% {
        opacity:0.8
    }
 `;

export const DesktopLayout = styled.div(
  (props) => css`
    flex-direction: column;
    @media (min-width: ${props.theme.breakpoints.lg + 1}px) {
      display: flex;
    }
  `
);

export const SkeletonWrapper = styled.div(
  () => css`
    padding-bottom: 0.5em;
    min-width: 100%;
  `
);

export const DesktopHeaderSection = styled.div((props) => {
  return css`
    display: flex;
    gap: 1.5em;
    flex-direction: row;
    padding: ${props.theme.spacing.fixed[8]}px;
    @media (max-width: ${props.theme.breakpoints.lg}px) {
      padding: ${props.theme.spacing.fixed[4]}px;
    }
  `;
});

export const DesktopWrapper = styled.div(() => {
  return css`
    width: 100%;
    display: flex;
    flex-direction: column;
  `;
});

export const HeadingWrapper = styled.div((props: any) => {
  return css`
    width: 10em;
    height: 20px;
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;
    align-items: flex-start;
    background: ${props.theme.color.monochrome4.default};
    animation: ${skeletonAnimation} 1.5s ease-in-out infinite;
  `;
});

export const DividerWrapper = styled.div((props) => {
  return css`
    padding-top: ${props.theme.spacing.fixed[3]}px;
    margin-bottom: ${props.theme.spacing.fixed[3]}px;
  `;
});

export const SubHeadingWrapper = styled.div(() => {
  return css`
    ${(props) => `
    width: 100%;  
    padding-top: ${props.theme.spacing.fixed[3]}px;
    height:100%;
    `};
  `;
});

export const ExpandedTileWrapper = styled.div((props) => {
  return css`
    margin: 2.25em auto;
    width: 100%;
    color: ${props.theme.color.monochrome6.default};
    background-color: ${props.theme.color.monochrome1.default};
    ${elevation(true)}
    border-radius: ${props.theme.border.radius["2"]};
  `;
});

export const IconWrapper = styled.div((props) => {
  return css`
    animation: ${skeletonAnimation} 1.5s ease-in-out infinite;
    background: ${props.theme.color.monochrome4.default};
    flex-shrink: 0;
    width: 175px;
    height: 175px;
    padding: ${props.theme.spacing.fixed[6]}px;
    border-radius: ${props.theme.border.radius["2"]};
    ${elevation(true)}
    @media (max-width: ${props.theme.breakpoints.lg}px) {
      width: 100px;
      height: 100px;
      padding: ${props.theme.spacing.fixed[3]}px;
    }
    @media (max-width: ${props.theme.breakpoints.md}px) {
      width: 75px;
      height: 75px;
      padding: ${props.theme.spacing.fixed[3]}px;
    }
  `;
});
