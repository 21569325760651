import React from "react";

import { Divider } from "@source-web/divider";
import { Heading } from "@source-web/heading";

import * as Styled from "./styles/ServiceTileSkeleton.style";

const ServiceTileSkeleton = () => (
  <Styled.ExpandedTileWrapper>
    <Styled.DesktopLayout>
      <Styled.DesktopHeaderSection>
        <Styled.IconWrapper />
        <Styled.DesktopWrapper>
          <Styled.HeadingWrapper />
          <Styled.DividerWrapper>
            <Divider appearance="secondary" />
          </Styled.DividerWrapper>
        </Styled.DesktopWrapper>
      </Styled.DesktopHeaderSection>
    </Styled.DesktopLayout>
  </Styled.ExpandedTileWrapper>
);

export function ServiceTileListSkeleton({ text }: { text?: string }) {
  return (
    <Styled.SkeletonWrapper>
      <Heading text={text} weight={3} size={1} />
      <ServiceTileSkeleton />
    </Styled.SkeletonWrapper>
  );
}
