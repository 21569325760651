import { DefaultTheme } from "styled-components";

import Dark from "./ServiceTile.dark.theme";
import Default from "./ServiceTile.default.theme";
import { ServiceTileThemeType } from "./ServiceTile.theme.types";

const getLocalTheme = (theme: DefaultTheme): ServiceTileThemeType => {
  switch (theme.name) {
    // @ts-ignore
    case "WS10Dark":
      return Dark(theme);
    default:
      return Default(theme);
  }
};

export default getLocalTheme;
