import { PageProps, graphql, navigate } from "gatsby";
import React, { useEffect, useMemo } from "react";

import { Grid, GridColumn, GridRow } from "@vfuk/core-grid";

import { ThemedSection } from "../../components";
import PageHeading from "../../components/PageHeading";
import { GetPageHeadingType } from "../../components/PageHeading/queries/queries.types";
import { handleSubscriptionIds, useEventSource } from "../../lib";
import {
  IMiddlewareSubscription,
  SubscriptionStatus
} from "../../lib/services/marketplaceService/subscriptions.types";
import { PageContext } from "../../types";
import ManageEmptyNotification from "./components/ManageEmptyNotification";
import { ServiceList } from "./components/ServiceList/";
import { ServiceTileListSkeleton } from "./components/ServiceList/ServiceTileListSkeleton";
import { ServiceTileNode } from "./components/ServiceList/types";
import {
  ContentfulStatuses,
  GetAllContentfulServiceTile,
  GetContactInformationType,
  GetContentfulMyServiceCard,
  MyServiceCard
} from "./queries/queries.types";
import { CategoryId } from "./types";

export const usePageHeadingData = graphql`
  query GetPageHeadingManage($locale: String) {
    contentfulPageHeading(
      pageName: { eq: "Manage" }
      node_locale: { eq: $locale }
    ) {
      heading
      headingBackgroundImageDark {
        gatsbyImageData(height: 450, quality: 95)
      }
      headingBackgroundImageLight {
        gatsbyImageData(height: 450, quality: 95)
      }
      headingBackgroundMobileDark {
        gatsbyImageData(height: 400, quality: 95)
      }
      headingBackgroundMobileLight {
        gatsbyImageData(height: 400, quality: 95)
      }
      headingSubtext
    }
    allContentfulServiceTile(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          serviceCategory {
            categoryName
          }
          heading
          productId
          serviceHlId
          isSelfServe
          isFree
          isForAppear
          customRouteName
          icon {
            file {
              url
            }
          }
          darkIcon {
            file {
              url
            }
          }
        }
      }
      distinct(field: serviceCategory___categoryName)
    }

    emptyNotification: contentfulContactInformation(
      pageName: { eq: "Empty Notification" }
      node_locale: { eq: $locale }
    ) {
      body {
        raw
      }
      heading
      usesButton
      actionText
      link
    }
    allContentfulStatuses(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          status
          key
        }
      }
    }
    contentfulMyServiceCard(node_locale: { eq: $locale }) {
      viewMoreButton
      viewLessButton
      viewButton
      statusLabel
      serviceDetailHeading
      recentReportsLabel
      openButton
      fullReportName
    }
  }
`;

interface PageHeadingType extends PageProps {
  data: GetPageHeadingType & {
    manage: GetContactInformationType["contentfulContactInformation"];
    emptyNotification: GetContactInformationType["contentfulContactInformation"];
  } & GetAllContentfulServiceTile &
    GetContentfulMyServiceCard &
    ContentfulStatuses;
  pageContext: PageContext;
}

export interface ServiceDataType {
  edges: Array<ServiceTileNode>;
  distinct: CategoryId[];
}

const ManagePage = ({
  data,
  pageContext: { formattedLocale, locale }
}: PageHeadingType) => {
  const {
    data: subscriptionData,
    isLoading,
    isError: error
  } = useEventSource<IMiddlewareSubscription>(
    `marketplace/subscriptions?status=${SubscriptionStatus.ACTIVE}`,
    {
      isSingleEntity: false
    }
  );

  const availableSubscriptionIds = useMemo(() => {
    return handleSubscriptionIds(subscriptionData, false);
  }, [subscriptionData]);

  // TODO: added for CYB-15452, remove after vsdm is ready on BE
  // availableSubscriptionIds.push("vsdm");

  const serviceTiles: ServiceDataType = data.allContentfulServiceTile;
  const myServiceCard: MyServiceCard = data.contentfulMyServiceCard;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const serviceParam = params.get("service");
    if (serviceParam) navigate(`${serviceParam}`);
  }, []);

  return (
    <main>
      <PageHeading
        headingText={data.contentfulPageHeading.heading}
        headingSubtext={data.contentfulPageHeading.headingSubtext}
        backgroundImgDark={
          data.contentfulPageHeading.headingBackgroundImageDark
        }
        longerLength
        formattedLocale={formattedLocale}
      />
      <ThemedSection fillAvailable appearance="secondary">
        <Grid maxWidth="spring">
          {
            // @TODO this `&& !availableSubscriptionIds`
            // needs to be removed after soft-launch
            // since we're mocking two products `Cybsafe and Ssc`.
            !isLoading && error && !availableSubscriptionIds ? (
              <GridRow>
                <GridColumn col={12}>
                  <ManageEmptyNotification
                    data={data.emptyNotification}
                    locale={formattedLocale}
                  />
                </GridColumn>
              </GridRow>
            ) : null
          }

          {isLoading ? (
            <ServiceTileListSkeleton text="Loading..." />
          ) : (
            <ServiceList
              {...{
                locale,
                formattedLocale,
                serviceTiles: serviceTiles.edges,
                availableSubscriptionIds,
                subscriptionData,
                myServiceCard,
                contentfulStatuses: data.allContentfulStatuses
              }}
            />
          )}
        </Grid>
      </ThemedSection>
    </main>
  );
};

export default ManagePage;
