import styled, { css } from "styled-components";

import { elevation } from "@source-web/mixins";

import { GridColumn } from "@vfuk/core-grid";

import localTheme from "../themes/ServiceTile.theme";
import { StyledServiceTileProps } from "./ServiceTile.style.types";

export const ReportsButton = styled.span`
  text-decoration: underline;
`;

export const ExpandedTileWrapper = styled.div<StyledServiceTileProps>(
  (props: StyledServiceTileProps) => {
    const appearanceFromProp = localTheme(props.theme);
    return css`
      width: 100%;
      color: ${appearanceFromProp.textColour};
      background-color: ${appearanceFromProp.background};
      ${elevation(true)}
      border-radius: ${props.theme.border.radius["2"]};
    `;
  }
);

export const DesktopLayout = styled.div(
  (props) => css`
    flex-direction: column;
    display: none;
    // +1 for tablet devices like Ipad Pro
    @media (min-width: ${props.theme.breakpoints.lg + 1}px) {
      display: flex;
    }
  `
);

export const TabletLayout = styled.div(
  (props) => `
  display: none;
  flex-direction:column;
  @media (min-width: ${props.theme.breakpoints.md}px) and (max-width: ${props.theme.breakpoints.lg}px) {
    display: flex;
  }
  `
);

export const MobileLayout = styled.div(
  (props) => `
    flex-direction:column;
    @media (min-width: ${props.theme.breakpoints.sm}px) {
      display: flex;
    }
    @media (min-width: ${props.theme.breakpoints.md}px) {
      display: none;
    }
  `
);

export const DesktopWrapper = styled.div<StyledServiceTileProps>(() => {
  return css`
    width: 100%;
    display: flex;
    flex-direction: column;
  `;
});

export const ButtonToggleContainer = styled.div<StyledServiceTileProps>(
  (props) => {
    return css`
      display: flex;
      flex-direction: column;
      gap: ${props.theme.spacing.fixed[5]}px;
      padding-bottom: ${props.theme.spacing.fixed[1]}px;
    `;
  }
);

export const IconWrapper = styled.div<StyledServiceTileProps>(
  (props: StyledServiceTileProps) => {
    const appearanceFromProp = localTheme(props.theme);
    return `
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 175px;
      height: 175px;
      top: 100px;
      padding: ${props.theme.spacing.fixed[6]}px;
      background-color: ${appearanceFromProp.iconBackground};
      border-radius: ${props.theme.border.radius["2"]};   
      ${elevation(true)}

      @media (max-width: ${props.theme.breakpoints.lg}px) {
        width: 100px;
        height: 100px;
        padding: ${props.theme.spacing.fixed[3]}px;
      }

      @media (max-width: ${props.theme.breakpoints.md}px) {
        width: 100px;
        height: 100px;
        padding: ${props.theme.spacing.fixed[3]}px;

        & > img {
          width: 68px;
          height: 68px;
        }
      }
      @media (min-width: ${props.theme.breakpoints.sm}px) and (max-width: ${
      props.theme.breakpoints.md
    }px) {
        width: 75px;
        height: 75px;
        padding: ${props.theme.spacing.fixed[3]}px;

        & > img {
          width: 43px;
          height: 43px;
        }
      }
    `;
  }
);

export const HeadingWrapper = styled.div<StyledServiceTileProps>(() => {
  return css`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1.01em;
    align-items: flex-start;
  `;
});

export const HeadingStatusWrapper = styled.div<StyledServiceTileProps>(() => {
  return css`
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  `;
});

export const DividerWrapper = styled.div<StyledServiceTileProps>((props) => {
  return css`
    padding-top: ${props.theme.spacing.fixed[3]}px;
  `;
});

export const DividerWrapperTransitional = styled.div<StyledServiceTileProps>(
  (props) => {
    return css`
      max-width: ${props.isExpanded ? "100%" : "0"};
      -webkit-transition: max-width 0.3s ease-out;
      -ms-transition: max-width 0.3s ease-out;
      -o-transition: max-width 0.3s ease-out;
      transition: max-width 0.3s linear;
      overflow: hidden;
    `;
  }
);

export const StatusWrapper = styled.div<StyledServiceTileProps>(() => {
  return css`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5em;
  `;
});

export const SubHeadingWrapper = styled.div<StyledServiceTileProps>(() => {
  return css`
    ${(props: StyledServiceTileProps) => `
    width: 100%;  
    padding-top: ${props.theme.spacing.fixed[3]}px;
    height:100%;
    `};
  `;
});

export const SubHeadingWrapperMobile = styled.div<StyledServiceTileProps>(
  () => {
    return css`
      ${(props: StyledServiceTileProps) => `
    width: 100%;  
    padding-top: ${props.theme.spacing.fixed[3]}px;
    height:100%;
    gap: 1.5em;
    display: flex;
    flex-direction: column;
    `};
    `;
  }
);

export const NoPaddingColumn = styled(GridColumn)`
  padding: 0;
`;

export const GridWrapper = styled.div<StyledServiceTileProps>(() => {
  return css`
    margin-top: 1.6em;
  `;
});

export const GridButtonWrapper = styled.div<StyledServiceTileProps>(() => {
  return css`
    display: flex;
    justify-content: flex-end;
    height: 2.2em;
    & button {
      color: #ffff;
    }
  `;
});

export const ButtonWrapperMobile = styled.div<StyledServiceTileProps>(
  (props) => {
    return `
      display: flex;
      gap: 1.5em;
      justify-content: space-between;
      align-items: center;
      flex-direction: column-reverse;
      @media (min-width: ${props.theme.breakpoints.md}px) and (max-width: ${props.theme.breakpoints.xl}px) {
        flex-direction: row;
      }
      @media (max-width: ${props.theme.breakpoints.sm}px) {
        flex-direction: column;
      }
    `;
  }
);

export const ViewMoreLessWrapper = styled.div<StyledServiceTileProps>(
  (props) => {
    return css`
      display: flex;
      align-items: center;
      gap: 0.5em;
      text-align: center;
      max-height: 100em;
      @media (max-width: ${props.theme.breakpoints.md}px) {
        justify-content: center;
      }
    `;
  }
);

export const InteractiveIconWrapper = styled.div<StyledServiceTileProps>(
  (props) => {
    const appearanceFromProp = localTheme(props.theme);
    return css`
      display: flex;
      align-items: center;
      gap: 0.5em;
      justify-content: center;
      cursor: pointer;
      color: ${appearanceFromProp.textColour};
      font-weight: 600;
      &:hover {
        opacity: 0.5;
      }
    `;
  }
);

export const SubTileContainer = styled.div<StyledServiceTileProps>((props) => {
  const appearanceFromProp = localTheme(props.theme);
  return css`
    color: ${appearanceFromProp.textColour};
    display: flex;
    flex-direction: column;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    gap: 1em;

    ${`@media (min-width: ${props.theme.breakpoints.md}px) and (max-width: ${props.theme.breakpoints.lg}px) {
      flex-basis: 50%;
    }`}
  `;
});

export const SectionSubTileWrapper = styled.div<StyledServiceTileProps>(
  (props) => {
    const appearanceFromProp = localTheme(props.theme);
    return css`
      background-color: ${appearanceFromProp.subtileBackground};
      color: ${appearanceFromProp.textColour};
      padding: 1em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: ${props.theme.border.radius["2"]};
    `;
  }
);

export const SectionSubTileUrl = styled.div<StyledServiceTileProps>((props) => {
  const appearanceFromProp = localTheme(props.theme);
  return css`
    color: ${appearanceFromProp.urlLinkColour};
    &:hover {
      opacity: 0.5;
    }
  `;
});

export const HiddenSectionWrapper = styled.div<StyledServiceTileProps>(
  (props) => {
    return css`
      max-height: ${props.isExpanded ? "700px" : "0"};
      -webkit-transition: max-height 0.25s ease-out;
      -ms-transition: max-height 0.25s ease-out;
      -o-transition: max-height 0.25s ease-out;
      transition: max-height 0.25s linear;
      overflow: hidden;
    `;
  }
);

export const HiddenSection = styled.div<StyledServiceTileProps>((props) => {
  return `
    @media (min-width: ${props.theme.breakpoints.xl}px) {
      padding-left: 14em;
      padding-right: 14em;
    }
    @media (min-width: ${props.theme.breakpoints.lg}px) and (max-width: ${props.theme.breakpoints.xl}px) {
      padding-left: ${props.theme.spacing.fixed[8]}px;
      padding-right: ${props.theme.spacing.fixed[8]}px;
    }
    @media (min-width: ${props.theme.breakpoints.md}px) and (max-width: ${props.theme.breakpoints.lg}px) {
      padding-left: ${props.theme.spacing.fixed[4]}px;
      padding-right: ${props.theme.spacing.fixed[4]}px;
    }
    padding-left: ${props.theme.spacing.fixed[4]}px;
    padding-right: ${props.theme.spacing.fixed[4]}px;
  `;
});

export const DesktopHeaderSection = styled.div<StyledServiceTileProps>(
  (props) => {
    return css`
      display: flex;
      gap: 1.5em;
      flex-direction: row;
      padding: ${props.theme.spacing.fixed[8]}px;
      @media (max-width: ${props.theme.breakpoints.lg}px) {
        padding: ${props.theme.spacing.fixed[4]}px;
      }
    `;
  }
);

export const MobileHeaderSection = styled.div<StyledServiceTileProps>(
  (props) => {
    return css`
      display: flex;
      flex-direction: column;
      padding: ${props.theme.spacing.fixed[8]}px;
      @media (max-width: ${props.theme.breakpoints.lg}px) {
        padding: ${props.theme.spacing.fixed[4]}px;
      }
    `;
  }
);
