import { navigate } from "gatsby";
import React from "react";
import styled, { useTheme } from "styled-components";

import { Container } from "@source-web/container";
import { Heading } from "@source-web/heading";

import { ContentfulRichText } from "../../../../components/ContentfulRichText";
import { PrimaryButton } from "../../../../components/PrimaryButton";
import { GetContactInformationType } from "../../queries/queries.types";

const EmptyNotificationWrapper = styled.div`
  padding: 20px;
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
`;

const NotificationContainer = styled.div`
  margin-bottom: 56px;
`;

interface ManageEmptyNotificationProps {
  locale: string;
  data: GetContactInformationType["contentfulContactInformation"];
}

function ManageEmptyNotification({
  data,
  locale
}: ManageEmptyNotificationProps) {
  const theme = useTheme();
  return (
    <NotificationContainer>
      <Container elevation>
        <EmptyNotificationWrapper>
          <Heading
            appearance="primary"
            inverse={theme.name !== "WS10"}
            text={data.heading}
            level={4}
          />
          <ContentfulRichText text={data.body.raw} />

          <ButtonContainer>
            <PrimaryButton
              className="button"
              text="Explore"
              onClick={() => navigate(`/${locale}/cybershop`)}
            />
          </ButtonContainer>
        </EmptyNotificationWrapper>
      </Container>
    </NotificationContainer>
  );
}

export default ManageEmptyNotification;
