import { ServiceTileThemeType } from "./ServiceTile.theme.types";

const serviceTileLocal = (theme: any): ServiceTileThemeType => {
  return {
    background: theme.color.primary4.default,
    iconBackground: "#454545",
    subtileBackground: theme.color.monochrome2.default,
    textColour: theme.color.monochrome6.default,
    urlLinkColour: theme.color.monochrome6.default
  };
};

export default serviceTileLocal;
