// @ts-nocheck
import { navigate } from "gatsby";
import { isArray } from "lodash";
import { paramCase } from "param-case";
import React from "react";
import { useTheme } from "styled-components";

import { Heading } from "@source-web/heading";
import { Pill } from "@source-web/pill";

import { GridColumn, GridRow } from "@vfuk/core-grid";

import ServiceTile from "../../../../components/ServiceTile";
import { WithLightMode } from "../../../../components/WithLightMode";
import {
  capitalizeFirstLetter,
  getProductUniqueInfo,
  trackServicePage
} from "../../../../lib";
import { appearanceBasedOnStatus } from "../../../profile/components/Plans/Plans";
import { ServiceTabsLocationState } from "../../../servicesPage/components";
import * as Styled from "../../styles/ManagePage.styles";
import { Categories, TileNode } from "../../types";
import { ServiceListPropType, ServiceTileNode } from "./types";

// TODO: align and fix logic later.
// const pillStateKeys: StateKeys = {
//   success: "success",
//   error: "error",
//   info: "info",
//   warn: "warn",
//   disabled: "disabled",
//   incomplete: "incomplete"
// };

// function getPillStateFromStatus(status: string): StateKeys {
//   switch (true) {
//     case status === Enums.ACTIVE || status === Enums.READY_TO_USE:
//       return pillStateKeys.success;

//     case status === Enums.ONBOARDING_IN_PROGRESS ||
//       status === Enums.REPORT_IN_PROGRESS ||
//       status === Enums.BOOK_WALKTHROUGH ||
//       status === Enums.ORDER_IN_PROGRESS:
//       return pillStateKeys.disabled;

//     case status === Enums.REPORT_COMPLETE ||
//       status === Enums.COMPLETE_ONBOARDING ||
//       status === Enums.WALKTHROUGH_COMPLETE:
//       return pillStateKeys.disabled;

//     case status === Enums.ORDER_CANCELLED || status === Enums.UNAVAILABLE:
//       return pillStateKeys.error;
//   }
// }

export function ServiceList({
  serviceTiles,
  availableSubscriptionIds,
  subscriptionData,
  locale,
  formattedLocale,
  myServiceCard,
  contentfulStatuses
}: ServiceListPropType) {
  const theme = useTheme();

  function openForm(heading: string, routeName: string | null) {
    // commenting for now, mobious products are not onboarded for the soft launch
    // if (
    //   status === Enums.ONBOARDING_IN_PROGRESS ||
    //   status === Enums.COMPLETE_ONBOARDING
    // ) {
    //   initialize({ flow: "campaign", heading });
    //   return;
    // }
    navigate(routeName ?? paramCase(heading));
    trackServicePage({ page_locale: locale, journey_type: heading });
  }

  function handleProductStatus(product: TileNode) {
    if (!subscriptionData) return;

    // If it's an array, we look for the Entry statuses.
    if (isArray(subscriptionData)) {
      const statusForSub = subscriptionData?.find(
        (subscription) =>
          getProductUniqueInfo(subscription).id === product.productId
      )?.status;

      // if the entry status if falsy, we return 'ACTIVE' as default
      // key, to track using the `currentStatus` const.
      // the mapping is between Contentful <-> Marketplace
      return statusForSub ?? "ACTIVE";
    }

    if (subscriptionData.id === product.productId)
      return subscriptionData.status;

    if (product.isFree) return "ACTIVE";
  }

  function mapProductToCategory(): Array<{
    categoryName: keyof Categories;
    products: ServiceTileNode[] & { status: string };
  }> {
    const categoriesMap = serviceTiles.reduce((acc, curr) => {
      const currentCategoryArray = acc[curr.node.serviceCategory.categoryName]
        ?.products
        ? acc[curr.node.serviceCategory.categoryName].products
        : [];

      const productStatus: string = handleProductStatus(curr.node);

      if (
        !localStorage.getItem("haveSScAccount") &&
        curr.node.serviceHlId === 10
      )
        return acc;

      if (
        !availableSubscriptionIds?.includes(curr.node.productId) &&
        !curr.node.isFree &&
        !curr.node.isForAppear
      )
        return acc;

      // Get the current status generated by `handleProductStatus`
      // and return the text contained in Contentful for
      // that specific status (translated or not.)
      const currentStatus = contentfulStatuses.edges.find(
        (item) => item.node.key === productStatus
      )?.node;

      return {
        ...acc,
        [curr.node.serviceCategory.categoryName]: {
          categoryName: curr.node.serviceCategory.categoryName,
          products: [
            ...currentCategoryArray,
            {
              ...curr.node,
              // Status text to be shown
              status: currentStatus?.status.toLocaleLowerCase(),

              // statusKey is used on the Pill component bellow,
              // `appearanceBasedOnStatus[product.statusKey]`
              statusKey: currentStatus?.key.toLowerCase()
            }
          ]
        }
      };
    }, {});

    return Object.values(categoriesMap);
  }

  const categories = mapProductToCategory();
  return (
    <Styled.ServiceListWrapper>
      {categories.map(({ categoryName, products }) => {
        const productData =
          formattedLocale === "it"
            ? products.filter((item) => item.productId !== "115620")
            : products;
        return (
          <div key={categoryName}>
            {categoryName ? (
              <Styled.CategoryText>
                <Heading
                  text={capitalizeFirstLetter(categoryName)}
                  weight={3}
                  size={1}
                />
              </Styled.CategoryText>
            ) : null}

            <GridRow key={categoryName} noGutters>
              {productData?.map((product) => {
                return (
                  <GridColumn
                    col={12}
                    marginBottom={12}
                    key={product.productId}
                  >
                    <ServiceTile
                      headingText={product.heading}
                      myServiceCard={myServiceCard}
                      isSelfServe={product.isSelfServe}
                      icon={
                        theme.name !== "WS10"
                          ? product?.darkIcon?.file?.url
                          : product.icon.file.url
                      }
                      recentReports={[
                        {
                          name: myServiceCard.fullReportName,
                          onClick: () => {
                            navigate(paramCase(product.heading), {
                              state: {
                                initialTab: "completeSection"
                              } as ServiceTabsLocationState
                            });
                          }
                        }
                      ]}
                      buttonProps={{
                        text: myServiceCard.openButton,
                        onClick: () =>
                          openForm(product.heading, product?.customRouteName)
                      }}
                      pillComponent={
                        <WithLightMode>
                          <Pill
                            state={appearanceBasedOnStatus[product.statusKey]}
                            text={capitalizeFirstLetter(product.status)}
                          />
                        </WithLightMode>
                      }
                    />
                  </GridColumn>
                );
              })}
            </GridRow>
          </div>
        );
      })}
    </Styled.ServiceListWrapper>
  );
}
